import { Component, Input } from '@angular/core';
import { environment } from '@env';

@Component({
  selector: 'awareness-center-victor-hero-banner',
  templateUrl: './victor-hero-banner.component.html',
  styleUrls: ['./victor-hero-banner.component.scss']
})
export class VictorHeroBannerComponent {
  @Input() scrollTarget: HTMLElement;

  environment = environment;

  scroll(el: HTMLElement): void {
    el.scrollIntoView({ block: 'start', behavior: 'smooth' });
  }
}
